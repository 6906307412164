import * as analytics from '@icepanel/app-analytics'

import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { modelObjectType, modelObjectTypes } from '@/modules/model/helpers/analytics-properties'
import { organizationId } from '@/modules/organization/helpers/analytics-group'
import { userCount, userId } from '@/modules/user/helpers/analytics-profile'

import { teamId } from './analytics-group'
import { teamColor, teamName } from './analytics-properties'

/**
 * Screens
 */

export const teamScreen = new analytics.Event({
  displayName: 'Team screen',
  name: 'team_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId,
  teamId
})

/**
 * Dialogs
 */

export const teamCreateDialog = new analytics.Event({
  displayName: 'Team create dialog',
  name: 'team_create_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId
})
export const teamUsersAddDialog = new analytics.Event({
  displayName: 'Team users add dialog',
  name: 'team_users_add_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId,
  teamId
})
export const teamDeleteDialog = new analytics.Event({
  displayName: 'Team delete dialog',
  name: 'team_delete_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId,
  teamId
})

/**
 * Menus
 */

export const teamPickerMenu = new analytics.Event({
  displayName: 'Team picker menu',
  name: 'team_picker_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId: {
    ...landscapeId,
    required: false
  },
  modelObjectTypes,
  organizationId
})
export const teamColorMenu = new analytics.Event({
  displayName: 'Team tag color menu',
  name: 'team_tag_color_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId,
  teamColor
})

/**
 * Events
 */

export const teamCreate = new analytics.Event({
  displayName: 'Team create',
  name: 'team_create'
}, {
  organizationId,
  teamId,
  teamName,
  userCount,
  userId
})
export const teamUpdate = new analytics.Event({
  displayName: 'Team update',
  name: 'team_update'
}, {
  organizationId,
  teamColor: {
    ...teamColor,
    required: false
  },
  teamId,
  teamName: {
    ...teamName,
    required: false
  },
  userCount: {
    ...userCount,
    required: false
  },
  userId: {
    ...userId,
    required: false
  }
})
export const teamDelete = new analytics.Event({
  displayName: 'Team delete',
  name: 'team_delete'
}, {
  organizationId,
  teamId
})
export const teamObjectAdd = new analytics.Event({
  displayName: 'Team object add',
  name: 'team_object_add'
}, {
  landscapeId: {
    ...landscapeId,
    required: false
  },
  modelObjectType,
  organizationId,
  teamId
})
export const teamObjectRemove = new analytics.Event({
  displayName: 'Team object remove',
  name: 'team_object_remove'
}, {
  landscapeId: {
    ...landscapeId,
    required: false
  },
  modelObjectType,
  organizationId,
  teamId
})
export const teamFilter = new analytics.Event({
  displayName: 'Team filter',
  name: 'team_filter'
}, {
  landscapeId,
  organizationId
})
export const teamFilterExclude = new analytics.Event({
  displayName: 'Team filter exclude',
  name: 'team_filter_exclude'
}, {
  landscapeId,
  organizationId
})
export const teamPin = new analytics.Event({
  displayName: 'Team pin',
  name: 'team_pin'
}, {
  landscapeId,
  organizationId
})
export const teamUnpin = new analytics.Event({
  displayName: 'Team unpin',
  name: 'team_unpin'
}, {
  landscapeId,
  organizationId
})
export const teamHide = new analytics.Event({
  displayName: 'Team hide',
  name: 'team_hide'
}, {
  landscapeId,
  organizationId
})
export const teamUnhide = new analytics.Event({
  displayName: 'Team unhide',
  name: 'team_unhide'
}, {
  landscapeId,
  organizationId
})
export const teamFocus = new analytics.Event({
  displayName: 'Team focus',
  name: 'team_focus'
}, {
  landscapeId,
  organizationId
})
export const teamUnfocus = new analytics.Event({
  displayName: 'Team unfocus',
  name: 'team_unfocus'
}, {
  landscapeId,
  organizationId
})
export const teamPinAll = new analytics.Event({
  displayName: 'Team pin all',
  name: 'team_pin_all'
}, {
  landscapeId,
  organizationId
})

export const teamUnpinAll = new analytics.Event({
  displayName: 'Team unpin all',
  name: 'team_unpin_all'
}, {
  landscapeId,
  organizationId
})
