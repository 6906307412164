
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { LandscapeModule } from '@/modules/landscape/store'
import { TeamModule } from '@/modules/team/store'

import * as analytics from '../../team/helpers/analytics'

@Component({
  components: {
    Dialog
  },
  name: 'TeamDeleteDialog'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get deleteTeamId () {
    return this.$queryValue('team_delete_dialog')
  }

  get teamId () {
    return this.$queryValue('team')
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get team () {
    return this.teamModule.teams.find(o => o.id === this.deleteTeamId)!
  }

  get teamDeleteStatus () {
    return this.teamModule.teamDeleteStatus
  }

  async deleteTeam () {
    const teamId = this.team.id
    const organizationId = this.team.organizationId

    await this.teamModule.teamDelete({
      organizationId,
      teamId
    })

    if (this.teamId === teamId) {
      const nextTeam = this.teamModule.teams.find(o => o)
      this.$replaceQuery({
        team: nextTeam?.id,
        team_delete_dialog: undefined
      })
    } else {
      this.$replaceQuery({
        team: undefined,
        team_delete_dialog: undefined
      })
    }
  }

  opened () {
    analytics.teamDeleteDialog.track(this, {
      organizationId: [this.team.organizationId],
      teamId: [this.team.id]
    })
  }
}
