
import { FormController, validationRules } from '@icepanel/app-form'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { AlertModule } from '@/modules/alert/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { UserModule } from '@/modules/user/store'

import * as analytics from '../helpers/analytics'
import { TeamModule } from '../store'

@Component({
  components: {
    Dialog
  },
  name: 'TeamCreateDialog'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  formController = new FormController({
    initialModel: {
      name: ''
    },
    validationRules: {
      name: [
        ...validationRules.exists,
        ...validationRules.maxLength(128)
      ]
    }
  })

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get teamCreateStatus () {
    return this.teamModule.teamCreateStatus
  }

  created () {
    this.formController.submitHandler = async model => {
      const team = await this.teamModule.teamCreate({
        create: {
          name: model.name
        },
        organizationId: this.currentOrganizationId
      })

      analytics.teamCreate.track(this, {
        organizationId: [team.organizationId],
        teamId: [team.id],
        teamName: team.name,
        userCount: team.userIds.length,
        userId: team.userIds
      })

      this.$replaceQuery({
        team: team.id,
        team_create_dialog: undefined
      })
    }
  }

  opened () {
    analytics.teamCreateDialog.track(this, {
      organizationId: [this.currentOrganizationId]
    })
  }

  closed () {
    this.formController.resetModel()
  }
}
