import * as analytics from '@icepanel/app-analytics'
import { TagColor } from '@icepanel/platform-api-client'

export const teamName: analytics.Property<string> = {
  displayName: 'Team name',
  name: 'team_name',
  required: true,
  type: 'string'
}
export const teamUserCount: analytics.Property<string> = {
  displayName: 'Team user count',
  name: 'team_user_count',
  required: true,
  type: 'string'
}
export const teamColor: analytics.Property<TagColor> = {
  displayName: 'Tag color',
  examples: ['beaver', 'black', 'blue', 'dark-blue', 'green', 'grey', 'orange', 'pink', 'purple', 'red', 'white', 'yellow'],
  name: 'tag_color',
  required: true,
  type: 'string'
}
